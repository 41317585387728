
<template>
    
    <div class="container">
        <div class="head">
            <el-button ref="sentMessage" type="primary" @click="sendFrpMessage()" plain>Frp-发送消息</el-button>
            <el-button ref="sentMessage" type="success" @click="sendTestMessage()" plain>Test-发送消息</el-button>
            <el-button ref="refreshMessage" type="info" @click="loadData()">刷新消息</el-button>
            <el-button ref="clearMessage" type="danger" @click="clearMessage()"  style="float:right;">清空消息</el-button>
        </div>
        <div class="content">
            <div v-for="(item,index) in dataList" :key="index">{{item}}</div>
        </div>

    </div>
</template>

<script>
import { messageQueueFindPost,messageQueueSendFrpPost,messageQueueSendTestPost,messageQueueClearPost } from '@/service/api'

export default {
    data(){
        return {
            dataList:[],
        }
    },
    mounted(){
        this.loadData();

        // this.timer = setInterval(() => {
        //     this.loadData();
        // }, 1000);
    },
    methods:{
        loadData(){
            
            messageQueueFindPost().then(res => {
                if(res.status_code == 0){
                    this.dataList = res.data;
                }
            }).finally(()=>{
            });
        },
        clearMessage(){
            messageQueueClearPost().then(res => {
                if(res.status_code == 0){
                    this.loadData();
                }
                
            }).finally(()=>{
            });
        },
        sendFrpMessage(){
            messageQueueSendFrpPost().then(res => {
               if(res.status_code == 0){
                    this.loadData();
                }
            }).finally(()=>{
            });
        },
        sendTestMessage(){
            messageQueueSendTestPost().then(res => {
               if(res.status_code == 0){
                    this.loadData();
                }
            }).finally(()=>{
            });
        },
    }
}
</script>

<style scoped>

    .container{
        padding:10px;
        box-sizing:border-box;
    }
    .content{
        margin-top:10px;
    }

</style>